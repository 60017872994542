<template>
    <div id="applicationTable">
        <div style="margin: 20px 20px 0 0; font-family: Times New Roman !important; font-size: 14px !important; word-wrap: break-word;" border="0">
            <p v-if="role === '1'" style="text-align: center;" v-html="$t('Application for revocation of the Registration Certificate from the legal entitiy')"></p>
            <p v-if="role === '2'" style="text-align: center;" v-html="$t('Application for revocation of the Registration Certificate from the individual (individual entrepreneur)')"></p>
            <p v-if="role === '1'">{{ $t('Order number') }}: {{order}}</p>
            <p v-if="role === '2'">{{ $t('Individual Identification Number') }}: {{docValue}}<br>{{ $t('(Individual tax number assigned to the individual after registration in the Taxing Entity)') }}</p>
            <p v-if="role === '1'">{{ $t('Company name') }}: {{orgName}}</p>
            <p v-if="role === '1'">{{ $t('Identification data of the employee of the legal entity on whose name the Registration certificates are issued') }}:</p>
            <p v-if="role === '1'">{{ $t('Individual Identification Number') }}: {{docValue}}</p>
            <p v-if="role === '1'">{{ $t('Number of an identity document, date of issue, name of issuing authority with an indication of the state of issue or unique number') }}: {{IdData}}</p>
            <p>{{ $t('Last name') }}: {{surname}}</p>
            <p>{{ $t('Name') }}: {{name}}</p>
            <p v-if="region">{{ $t('Region') }}: {{region}}</p>
            <p v-if="city">{{ $t('City') }}: {{city}}</p>
            <p>{{ $t('Email app') }}: {{email}}</p>
            <p>{{ $t('Registration Certificate identification data') }}:<br> {{ $t('Serial number') }}: {{sn}}</p>
            <p>{{ $t('Date') }}: {{ date | moment("DD MMMM YYYY") }}</p>
            <p v-if="role === '1'">{{ $t('Signature of the employee of the legal entity (legal representative)') }}: _________________</p>
            <p v-if="role === '2'">{{ $t('Signature of the employee of the legal entity (individual entrepreneur representative)') }}: _________________</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ApplicationForRevoke',
  props: [
    'params',
    'order'
  ],
  data () {
    return {
      docValue: null,
      orgName: null,
      IdData: null,
      surname: null,
      name: null,
      region: null,
      city: null,
      email: null,
      role: null,
      sn: null,
      certificates: null,
      certificateInfo: null
    }
  },
  watch: {
    params: {
      handler: function (val) {
        if (val) {
          this.docValue = val.docnum
          this.region = val.region
          this.city = val.city
          this.orgName = val.legal ? (val.legal + ' ' + val.name) : val.name
          this.email = val.e_mail
          let re = /\s*%\s*/
          let name = this.params.fio.split(re)
          this.surname = name[0]
          this.name = name[1]
          this.role = val.type
          this.IdData = val.IdData ? val.IdData.replace(/%/g, ', ') : ''
        }
      },
      immediate: true
    },
    certificates: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/get-certificates/', { params: { order: this.order } }).then(response => {
            if (response.data) {
              this.certificates = response.data
              this.setSN()
            }
          })
        }
      },
      immediate: true
    },
    certificateInfo: {
      handler: function (val) {
        if (val) {
          this.sn = val.serialNumber
        }
      },
      immediate: true
    }
  },
  computed: {
    date () {
      return new Date()
    }
  },
  methods: {
    async setSN () {
      this.certificateInfo = await this.cryptoSocket.getCertificateInfo({ id: 5, certificate: this.certificates[0].certificate, objectName: this.order })
      this.sn = this.certificateInfo.serialNumber
    }
  }
}
</script>

<style scoped>
  #applicationTable {
    display: none;
  }
</style>
