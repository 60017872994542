<template>

  <div>
    <section class="section">
      <div v-if="scanApp || result === '3'" class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Certificate revocation request sent') }}</h1>
        <p class="has-text-grey-light">{{ $t('and pending at operator') }}</p>
      </div>

      <div v-if="!scanApp && result !== '3'" class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Complete the application form for the revocation of the EDS key certificate') }}</h1>
      </div>
    </section>
    <section class="section" v-show="!visible">
      <div class="container">
        <div class="content">
          <div style="height:5rem;"></div>
          <div class="columns is-centered">
            <img src="@/assets/img/loading3.gif" style="height: 250px">
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="allcont" v-show="visible">
      <div v-if="scanApp || result === '3'" class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <article class="message is-primary">
                <div class="message-header">
                  <p>{{ $t('Information about your order') }}</p>
                </div>
                <div class="message-body has-user-border">
                  <p>{{ $t('Your order number', [order]) }}.</p>
                  <p>{{ $t('An email with a link to order information is sent to your Email address') }}.</p>
                  <p>{{ $t('Please, do not repeat the revoke procedure again until your current request has been processed') }}.</p>
                  <p v-if="result === '1'">{{ $t('Once the request is processed, you will receive an invoice. After paying the bill, the certificate will be revoked') }}.</p>
                  <p v-if="result === '3'">{{ $t('Once the request is processed by the administrator certificate is revoked') }}.</p>
                  <p v-html="$t('After the revocation procedure completed the certificate serial number will be included in', [$t('CRL')])"></p>
                </div>
              </article>
              <router-link to="/" class="button is-primary is-medium is-flex-mobile">{{ $t('Go back to the main page') }}</router-link>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!scanApp && result !== '3'" class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <p class="title is-5">1. {{ $t('Print the application form for the revocation of the EDS key certificate') }}.</p>
              <a class="button" @click="print"><span class="file-icon"><img src="@/assets/icons/print.svg"></span><span>{{ $t('Print') }}</span></a>
              <a class="button" @click="save"><span class="file-icon"><img src="@/assets/icons/download.svg"></span><span>{{ $t('Save') }}</span></a>
              <p class="help">{{ $t('If there are any printing problems for app') }}.</p>
              <div style="height: 1rem;"></div>
              <p class="title is-5 is-marginless">2. {{ $t('Put the handwritten signature of the owner of the EDS key certificate on the printed application form') }}.</p>
              <div style="height: 1rem;"></div>
              <p class="title is-5 is-marginless">3. {{ $t('Scan the signed application form for the revocation of the EDS key certificate') }}.</p>
              <p class="help">{{ $t('Save the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)') }}.</p>
              <div style="height: 1rem;"></div>
              <p class="title is-5">4. {{ $t('Attach the scanned and signed application form for the revocation of the EDS key certificate') }}.</p>

              <div class="file has-name is-primary">
                <label class="file-label">
                  <input name="file" @change="processFile" class="file-input" accept=".jpg,.png,.pdf,.tif" type="file">
                  <input name="AppFileName" type="hidden" :value="{inptFileNameApp}"/>
                  <input name="AppFileBlob" type="hidden" :value="{inptFileBlobApp}"/>
                  <span class="file-cta">
                      <span class="file-icon"><img src="@/assets/icons/attach.svg"></span>
                      <span class="file-label">{{ $t('Attach an application') }}</span>
                  </span>
                  <span class="file-name" :class="{'has-text-danger': isTextDanger, 'has-text-primary': isTextPrimary}">{{AppFileText}}</span>
                </label>
              </div>

              <div style="height: 2rem;"></div>

              <div class="buttons">
                <input class="button is-primary is-large" type="button" :disabled="!inptFileNameApp" v-on:click="GoNext" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>

        <applicationForm :params="params" :order="order"/>

      </div>

    </section>
  </div>

</template>

<script>
import applicationForm from '../pages/ApplicationForRevoke'
import fileSaver from 'file-saver'
export default {
  name: 'RevokeResult',
  components: {
    applicationForm
  },

  data () {
    return {
      params: null,
      isTextDanger: true,
      isTextPrimary: false,
      AppFileText: this.$i18n.t('No document selected').toString(),
      inptFileNameApp: null,
      inptFileBlobApp: null,
      scanApp: null,
      result: null,
      visible:true,
    }
  },
  created() {
    if(this.revokeDataForCheckingOrder) {
      this.$http.post('/certificate/check-order', this.revokeDataForCheckingOrder).then(response => {
        if (response.data) {
          this.result = response.data;
        }
      })
    }
  },
  computed: {
    order () {
      return this.$store.state.order ? this.$store.state.order : (this.params ? this.params.order : null)
    },
    // result () {
    //   return this.$store.state.result ? this.$store.state.result : ''
    // },
    revokeData () {
      return this.$store.state.revokeData ? this.$store.state.revokeData : null
    },
    revokeDataForCheckingOrder(){
      if(this.$store.state.revokeData){
        const {oid, role} = this.$store.state.revokeData
        return {
          oid,
          role
        }
      }
    }
  },
  watch: {
    params: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/get-user-info/', { params: { order: this.order } }).then((response) => {
            if (response.data) {
              this.params = response.data
              this.scanApp = this.params.rscanapp
              this.$store.commit('updateOrder', null)
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    processFile (event) {
      if (event) {
        const file = event.target.files[0]
        if (file) {
          if (file.size > 2097152) {
            this.AppFileText = this.$i18n.t('File size should not exceed 2MB').toString()
            this.inptFileNameApp = null
            this.inptFileBlobApp = null
            this.isTextDanger = true
            this.isTextPrimary = false
          } else {
            this.AppFileText = file.name
            this.isTextDanger = false
            this.isTextPrimary = true
            const reader = new FileReader()
            reader.onload = e => this.inptFileBlobApp = (e.target.result).split(',')[1]
            reader.readAsDataURL(file)
            this.inptFileNameApp = file.name
          }
        } else {
          this.AppFileText = this.$i18n.t('No document selected').toString()
          this.inptFileNameApp = null
          this.inptFileBlobApp = null
          this.isTextDanger = true
          this.isTextPrimary = false
        }
      }
    },
    GoNext () {
      this.visible = !this.visible,
      this.$validator.validateAll().then((result) => {
        this.$http.post('/certificate/save-revoke', this.revokeData).then(response => {
          if (response.data) {
            this.$http.post('/certificate/doc/',
                { order: this.order, fileName: this.inptFileNameApp, fileBlob: this.inptFileBlobApp, docAppRevoke: 'docAppRevoke' })
                .then(response => {
                  if (response.data) {
                    this.scanApp = true
                    this.visible = !this.visible
                  }
                })
          }
        })
      })
    },
    print () {
      const printContents = document.getElementById('applicationTable').innerHTML
      const WinPrint = window.open('', '', 'left=50,top=50,width=900,height=750,toolbar=0,scrollbars=1,status=0')
      WinPrint.document.open()
      WinPrint.document.write(printContents)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    save () {
      const certBody = '<!DOCTYPE><html><head><meta http-equiv="content-type" content="text/html; charset=utf-8"></head><body>' + document.getElementById('applicationTable').innerHTML + '</body></html>'
      this.$http.post('/certificate/pdf-make', { content: certBody, order: this.order }, {
        responseType: 'blob'
      }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, 'Application_' + this.order + '.pdf')
        }
      })
    }
  }
}
</script>

<style scoped>
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .file-name {
    max-width: 700px !important;
  }
</style>
